import { PlusOutlined, ArrowRightOutlined, FileSearchOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Layout, Input, Button, Modal, Form, Breadcrumb, List, Row, Col, Tooltip, message, Popconfirm } from 'antd';
import React, { Component } from 'react';
import axios from 'axios';
import Configuration from '../Configuration.js';
import { Navigate, useParams } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import SideMenu from '../Components/SideMenu';
import 'antd/dist/reset.css';

const { Header, Content, Sider } = Layout;
const items = Configuration.sideMenu();
const { Search } = Input;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


class Categorias extends Component {
  constructor(props) {
    super(props);

    // Auth
    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    // State
    this.state = {
      searching: false, 
      redirect: false, 
      redirectUrl: '', 
      records: [], 
      carrers: [],
      loading: false, 
      openModal: false, 
      modalTitle: '', 
      selectedRecord: {}, 
      openModalInstrument: false,
      openModalQuestion: false, 
      modalTitleQuestion: '', 
      selectedQuestion: {}, 
      acreditor: {},
      carrer: {},
      openModalCarrers: false
    };
  }

  componentDidMount() {
    const params = this.props.params;
    if ('acreditor' in params && 'carrer' in params) {
      this.getAcreditor(params.acreditor);
      this.getCarrer(params.carrer);
      this.getRecords(params.carrer);
    }
  }

  getAcreditor(id) {
    const query = `{getAcreditor(id: ${id}){id,shortName}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el órgano acreditador.');
      }

      this.setState({acreditor: resp.data.data.getAcreditor});
    });
  }

  getCarrer(id) {
    const query = `{getCarrer(id: ${id}){id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar la carrer.');
      }

      this.setState({carrer: resp.data.data.getCarrer});
    });
  }

  getRecords(id) {
    const query = `{listCriteriaCategoriesFromCarrer(id: ${id}){id,criteria,criteriaCategory{id,name,folio}}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar las categorías.');
      }

      this.setState({records: resp.data.data.listCriteriaCategoriesFromCarrer});
    });
  }

  handleCreateModal() {
    this.setState({openModal: true, openModalCarrers: false, modalTitle: 'Nueva categoría'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('name' in this.state.selectedRecord) {
      this.updateRecord(data);
    } else {
      this.createRecord(data);
    }
  }

  createRecord(record) {
    // Add new record
    const query = `mutation{createCriteriaCategoryFromCarrer(id: ${this.state.carrer.id}, name: "${record.name}", folio: "${record.folio}"){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al crear el registro. Llena todos los campos obligatorios.');
      }

      const response = resp.data.data.createCriteriaCategoryFromCarrer;
      // console.log(response);
      this.setState({openModal: false, modalTitle: '', });
      this.getRecords(this.state.carrer.id);
    });
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar categoría', selectedRecord: record.criteriaCategory});
  }

  updateRecord(record) {
    const selected = this.state.selectedRecord;
    const query = `mutation{updateCriteriaCategory(id: ${selected.id}, name: "${record.name}", folio: "${record.folio}"){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        // console.log(resp.data);
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      const response = resp.data.data.updateCriteriaCategory;
      // console.log(response);
      this.setState({openModal: false, selectedRecord: {}, modalTitle: '', loading: false});
      this.getRecords(this.state.carrer.id);
    });
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  deleteRecord(record) {
    const query = `mutation{unassignCriteriaCategory(id: ${record.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      this.getRecords(this.state.carrer.id);
    });
  }

  handleopenModalCarrers() {
    const query = `{listCriteriaCategories{id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        // console.log(resp.data);
        return message.warning('Error al obtener las carreras.');
      }

      this.setState({openModalCarrers: true, carrers: resp.data.data.listCriteriaCategories});
    });
  }

  handleCancelModalCarrers() {
    this.setState({openModalCarrers: false});
  }

  handleAssignCarrer(record) {
    const query = `mutation{assignCriteriaCategory(criteriaCategoryId: ${record.id}, carrerId: ${this.state.carrer.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al asignar el registro.');
      }

      this.setState({openModalCarrers: false, carrers: []});
      this.getRecords(this.state.carrer.id);
    });
  }

  statusColor(status) {
    switch(status) {
      case 'Pendiente':
        return '#FBDB14';
      case 'En revisión':
        return '#FA8C16';
      case 'Con errores':
        return '#F5212D';
      case 'Pendiente':
        return '#FBDB14';
      case 'Autorizado':
        return '#52C51A';
      case 'Pendiente':
        return '#FBDB14';
    }
  }

  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout>
        {/* Modal for new and edit */}
        <Modal
          open={this.state.openModal}
          title={this.state.modalTitle}
          onOk={this.handleSubmitModal.bind(this)}
          onCancel={this.handleCancelModal.bind(this)}
          footer={false}
        >
          <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={this.state.selectedRecord} onFinish={this.handleSubmitModal.bind(this)} onFinishFailed={() => {}} autoComplete="off">
            <Form.Item label="Número de categoría" name="folio" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item>

            <Form.Item label="Nombre" name="name" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
              <Button onClick={this.handleCancelModal.bind(this)} style={{marginRight: 5}}>Cancelar</Button>
              <Button type="primary" htmlType="submit">Guardar</Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Moal for assign carrer */}
        <Modal
          open={this.state.openModalCarrers}
          title={'Asignar categoría'}
          onOk={() => {}}
          onCancel={this.handleCancelModalCarrers.bind(this)}
          footer={false}
        >
          <div>
            <div style={{borderBottom: 'solid #ddd 1px', paddingBottom: 10, display: 'flex', justifyContent: 'center'}}>
              <Button icon={<PlusOutlined />} size="large" style={{backgroundColor: '#1B3C6A', color: '#fff'}} onClick={this.handleCreateModal.bind(this)}>Nueva categoría</Button>
            </div>

            <List
            itemLayout="horizontal"
            dataSource={this.state.carrers}
            renderItem={item => (
              <List.Item key={`car-${item.id}`}>
                <List.Item.Meta                      
                  title={<p style={{fontSize: '1.1em'}}>{item.name}</p>}
                  style={{textAlign: 'left'}}
                />
                
                <Tooltip placement="top" title={'Asignar'}>
                  <Button shape="circle" type="primary" ghost style={{marginRight: 5}} onClick={this.handleAssignCarrer.bind(this, item)}><ArrowRightOutlined /></Button>
                </Tooltip>
              </List.Item>
            )}/>
          </div>
        </Modal>
        
        {/* Top menu */}
        <TopMenu />

        <Layout className="site-layout">
          <SideMenu />

          <Content style={{margin: '24px 16px 0', background: '#fff'}}>
            <div style={{padding: 24, textAlign: 'center'}}>
            <div style={{ margin: '16px 0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}>
                  <div style={{marginBottom: 10}}>Ir a</div>
                  <div style={{marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                    <a href="/organos-acreditadores" style={{background: '#1890ff', color: 'white', padding: '0px 5px', borderRadius: 5}}>Organos acreditadores</a>
                  </div>
                  <div style={{marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                    <a href={`/organo-acreditador/${this.state.acreditor.id}`} style={{background: '#1890ff', color: 'white', padding: '0px 5px', borderRadius: 5}}>Carreras de {this.state.acreditor.shortName}</a>
                  </div>
                </div>

              <br />

              <div className='container-between' style={{marginBottom: '30px', alignItems: 'center'}}>
                <h3 style={{marginBottom: 0, color: 'rgb(27, 60, 106)', textTransform: 'uppercase'}}>Categorías</h3>
                
                <div>
                  <Button icon={<PlusOutlined />} size="large" style={{backgroundColor: '#1B3C6A', color: '#fff'}} onClick={this.handleopenModalCarrers.bind(this)}>Asignar categoría</Button>
                </div>
              </div>

              <br />

              <div className="site-layout-background" style={{ padding: 24, minHeight: 380, borderTop: '1px solid #ccc' }}>
                {/* Content */}
                <div className="">
                  <List
                    itemLayout="horizontal"
                    dataSource={this.state.records}
                    renderItem={item => (
                      <List.Item key={`car-${item.id}`}>
                        <Row style={{width: '100%'}}>
                          <Col flex="1 1 200px">
                            <List.Item.Meta 
                              title={
                                <div>
                                  <p style={{marginBottom: 0}}>{item.criteriaCategory?.folio}</p>
                                  <a href={`/acreditador/${this.state.acreditor.id}/carrera/${this.state.carrer.id}/categorias/${item.criteriaCategory?.id}/criterios`} style={{fontSize: '1.2em'}}>{item.criteriaCategory?.name}</a>
                                </div>
                              }
                              description={`Criterios: ${item.criteria ? item.criteria : 0}`} 
                              style={{textAlign: 'left'}}
                            />
                          </Col>

                          <Col span={4}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                              <Tooltip placement="top" title={'Detalles'}>
                                <Button shape="circle" type="primary" ghost style={{marginRight: 5}} href={`/acreditador/${this.state.acreditor.id}/carrera/${this.state.carrer.id}/categorias/${item.criteriaCategory?.id}/criterios`}><FileSearchOutlined /></Button>
                              </Tooltip>
                              <Tooltip placement="top" title={'Editar'}>
                                <Button shape="circle" type="primary" ghost style={{marginRight: 5}} onClick={this.handleEditModal.bind(this, item)}><EditOutlined /></Button>
                              </Tooltip>
                              <Tooltip placement="bottom" title={'Borrar'}>
                                <Button shape="circle" danger>
                                  <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteRecord.bind(this, item)}>
                                    <DeleteOutlined />
                                  </Popconfirm>
                                </Button>
                              </Tooltip>
                            </div>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default withParams(Categorias);
