import {
  AppstoreOutlined,
  UserOutlined,
  BookOutlined,
  ExceptionOutlined,
  LineChartOutlined
} from '@ant-design/icons';
import React from 'react';

const Configuration = {
  // apiServer: 'http://127.0.0.1:4000',
  // staticServer: 'http://127.0.0.1:4000',
  // wsServer: 'http://127.0.0.1:5000',
  apiServer: 'https://dcea-api.innovaster.co',
  staticServer: 'https://dcea-api.innovaster.co',
  wsServer: 'https://ws-industrial.innovaster.co',
  isInRol(rol, rols) {
    const list = rols.split(" or ");

    if(rol && list.indexOf(rol.toLowerCase()) > -1) {
      return true;
    } else {
      return false;
    }
  },
  sideMenu() {
    return [
      {key: 1, icon: React.createElement(AppstoreOutlined), label: 'Órganos'},
      {key: 2, icon: React.createElement(BookOutlined), label: 'Áreas'},
      {key: 3, icon: React.createElement(UserOutlined), label: 'Usuarios'},
      {key: 4, icon: React.createElement(LineChartOutlined), label: 'Reportes'},
      {key: 5, icon: React.createElement(ExceptionOutlined), label: 'Servidor'}
    ];
  },
  sideMenuCoor() {
    return [
      {key: 'coord-area', icon: React.createElement(BookOutlined), label: 'Área'},
      {key: 'coord-indicadores', icon: React.createElement(BookOutlined), label: 'Indicadores'},
    ];
  },
  sideMenuProf() {
    return [
      {key: 'prof-indicadores', icon: React.createElement(BookOutlined), label: 'Indicadores'},
    ];
  },
  getRoute(item) {
    switch(item.key) {
      case '1':
        return '/organos-acreditadores';
      case '2':
        return '/areas';
      case '3':
        return '/usuarios';
      case '4':
        return '/reportes';
      case '5':
        return '/servidor';
      case 'coord-area':
        return '/area/1';
      case 'coord-indicadores':
        return '/area-indicadores/1';
      case 'prof-indicadores':
        return '/usuario-indicadores/1';
    }
  },
  redirectUrl(global) {  
    if (global) {
      return '/organos-acreditadores';
    } else {
      return '/mis-indicadores';
    }
  },
  getStatus(status) {
    if(status) {
      return {color: 'success', text: 'Activo'};
    } else {
      return {color: 'danger', text: 'Inactivo'};
    }
  },
  getBadgeStatus(status) {
    if(status) {
      return {color: 'green', text: 'Resuelto'};
    } else {
      return {color: 'gold', text: 'Pendiente'};
    }
  },
  getVisitStatus(status) {
    switch(status) {
      case 'pending':
        return {color: 'gold', hex: '#FEBF07', text: 'Pendiente'};
      case 'authorized':
        return {color: 'green', hex: '#00D755', text: 'Autorizada'};
      case 'denied':
        return {color: 'red', hex: '#FF0007', text: 'Denegada'};
      case 'access':
        return {color: 'lime', hex: '#9DEC0E', text: 'En planta'};
      case 'reception':
        return {color: 'volcano', hex: '#FF3B4F', text: 'En recepción'};
      case 'personal':
        return {color: 'blue', hex: '#2A78FF', text: 'Recibida'};
      case 'leave':
        return {color: 'magenta', hex: '#FE5193', text: 'Ha salido'};
      default: 
        return {color: 'gold', hex: '#FEBF07', text: 'Pendiente'};
    }
  },
  displayElement(status) {
    if(status) {
      return 'block';
    } else {
      return 'none';
    }
  },
  formatDatetime(date) {
    if(date) {
      const newDate = new Date(date);
      return `${newDate.getDate()} / ${newDate.getMonth() + 1} / ${newDate.getFullYear()} ${newDate.getHours()}:${newDate.getMinutes()}`;
    } else {
      return 'Sin fecha';
    }
  },
  formatDate(date) {
    if(date) {
      const newDate = new Date(date);
      return `${newDate.getDate()} / ${newDate.getMonth() + 1} / ${newDate.getFullYear()}`;
    } else {
      return 'Sin fecha';
    }
  },
  formatTime(date) {
    if(date) {
      const newDate = new Date(date);
      return `${newDate.getHours()}:${newDate.getMinutes()}`;
    } else {
      return 'Sin fecha';
    }
  },
  stayTime(start, end) {
    if(start && end) {
      const startDate = new Date(start).getTime();
      const endDate = new Date(end).getTime()
      const stay = endDate - startDate;
      
      return Math.ceil((stay / 1000) / 60);
    } else {
      return 'Pendiente';
    }
  },
  editorModules: {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    },
  },
  editorFormats: [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'indent',
    'link',
    'image',
  ]
};

export default Configuration;
