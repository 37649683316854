import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './App.css';

// Acceso
import Login from './pages/Login';
import Recuperar from './pages/Recuperar';

// Organos acreditadores
import Organos from './pages/Organos';
import OrganoDetalles from './pages/OrganoDetalles';

// Areas
import Areas from './pages/Areas';
import AreaDetalles from './pages/AreaDetalles';
import AreaIndicadores from './pages/AreaIndicadores';
// Categorias
import Categorias from './pages/Categorias';
// Criterios
import Criterios from './pages/Criterios';
import CriterioIndicadores from './pages/CriterioIndicadores';
// Indicadores
import IndicadorDetalles from './pages/IndicadorDetalles';
import IndicadorReporte from './pages/IndicadorReporte';
// Usuarios
import Usuarios from './pages/Usuarios';
import UsuarioIndicadores from './pages/UsuarioIndicadores';
import MisIndicadores from './pages/MisIndicadores';
import Perfil from './pages/Perfil';
// Roles
import Roles from './pages/Roles';
// Notificaciones
import Notificaciones from './pages/Notificaciones';
import Servidor from './pages/Servidor';
import Limpiar from './pages/Limpiar';
// Reportes
import Reportes from './pages/Reportes';


const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Login/>} />
      <Route path="/login" element={<Login/>} />
      <Route path="/Recuperar" element={<Recuperar/>} />
      {/* Organos */}
      <Route path="/organos-acreditadores" element={<Organos/>} />
      <Route path="/organo-acreditador/:id" element={<OrganoDetalles/>} />
      <Route path="/acreditador/:acreditor/carrera/:carrer/categorias" element={<Categorias/>} />
      <Route path="/acreditador/:acreditor/carrera/:carrer/categorias/:categoria/criterios" element={<Criterios/>} />
      <Route path="/acreditador/:acreditor/carrera/:carrer/categorias/:categoria/criterio/:criterio/indicadores" element={<CriterioIndicadores/>} />
      {/* Areas */}
      <Route path="/areas" element={<Areas/>} />
      <Route path="/area/:id" element={<AreaDetalles/>} />
      <Route path="/area-indicadores/:id" element={<AreaIndicadores/>} />
      {/* Indicadores */}
      <Route path="/indicador/:id" element={<IndicadorDetalles/>} />
      <Route path="/indicador-reporte/:id" element={<IndicadorReporte/>} />
      {/* Usuarios */}
      <Route path="/Usuarios" element={<Usuarios/>} />
      <Route path="/mis-indicadores" element={<MisIndicadores/>} />
      <Route path="/usuario-indicadores/:id" element={<UsuarioIndicadores/>} />
      <Route path="/perfil" element={<Perfil/>} />
      {/* Roles */}
      <Route path="/roles" element={<Roles/>} />
      {/* Notificaciones */}
      <Route path="/notificaciones" element={<Notificaciones/>} />
      <Route path="/servidor" element={<Servidor/>} />
      <Route path="/Limpiar" element={<Limpiar/>} />
      {/* Reportes */}
      <Route path="/reportes" element={<Reportes/>} />
    </Routes>
  </Router>
)  

export default App;
