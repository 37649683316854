import { PlusOutlined, ArrowRightOutlined, FileSearchOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Layout, Input, Button, Modal, Form, Breadcrumb, List, Tooltip, message, Popconfirm } from 'antd';
import React, { Component } from 'react';
import axios from 'axios';
import Configuration from '../Configuration.js';
import { Navigate, useParams } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import SideMenu from '../Components/SideMenu';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

const { Content } = Layout;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


class Categorias extends Component {
  constructor(props) {
    super(props);

    // Auth
    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    // State
    this.state = {
      searching: false, 
      redirect: false, 
      redirectUrl: '', 
      records: [], 
      carrers: [],
      loading: false, 
      openModal: false, 
      modalTitle: '', 
      selectedRecord: {}, 
      openModalInstrument: false,
      openModalQuestion: false, 
      modalTitleQuestion: '', 
      selectedQuestion: {}, 
      acreditor: {},
      carrer: {},
      category: {},
      openModalCarrers: false,
      recordDescription: ''
    };
  }

  componentDidMount() {
    const params = this.props.params;
    if ('acreditor' in params && 'carrer' in params) {
      this.getAcreditor(params.acreditor);
      this.getCarrer(params.carrer);
      this.getCategory(params.categoria);
      this.getRecords(params.categoria);
    }
  }

  getAcreditor(id) {
    const query = `{getAcreditor(id: ${id}){id,shortName}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el órgano acreditador.');
      }

      this.setState({acreditor: resp.data.data.getAcreditor});
    });
  }

  getCarrer(id) {
    const query = `{getCarrer(id: ${id}){id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar la carrera.');
      }

      this.setState({carrer: resp.data.data.getCarrer});
    });
  }

  getCategory(id) {
    const query = `{getCriteriaCategory(id: ${id}){id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar la categoria.');
      }

      this.setState({category: resp.data.data.getCriteriaCategory});
    });
  }

  getRecords(id) {
    const query = `{listCriteriaFromCategory(id: ${id}){id,indicators,criteria{id,name,number,description}}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        // console.log(resp.data);
        return message.warning('Error al consultar los criterios.');
      }

      // console.log(resp.data.data.listCriteriaFromCategory);
      this.setState({records: resp.data.data.listCriteriaFromCategory});
    });
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo criterio'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('name' in this.state.selectedRecord) {
      this.updateRecord(data);
    } else {
      this.createRecord(data);
    }
  }

  createRecord(record) {
    // Add new record
    const body = {data: {description: this.state.recordDescription}};
    const query = `mutation{createCriteriaFromCategory(id: ${this.state.category.id}, name: "${record.name}", number: "${record.number}"){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, body, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al crear el registro. Llena todos los campos obligatorios.');
      }

      const response = resp.data.data.createCriteriaFromCategory;
      console.log(response);
      this.setState({openModal: false, modalTitle: '', });
      this.getRecords(this.state.category.id);
    });
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar criterio', selectedRecord: record.criteria});
  }

  updateRecord(record) {
    const selected = this.state.selectedRecord;
    const body = {data: {description: this.state.recordDescription}};
    const query = `mutation{updateCriteria(id: ${selected.id}, name: "${record.name}", number: "${record.number}"){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, body, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        // console.log(resp.data);
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      // const response = resp.data.data.updateCriteria;
      // console.log(response);
      this.setState({openModal: false, selectedRecord: {}, modalTitle: '', loading: false});
      this.getRecords(this.state.category.id);
    });
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  deleteRecord(record) {
    const query = `mutation{unassignCriteria(id: ${record.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      this.getRecords(this.state.category.id);
    });
  }

  handleopenModalCarrers() {
    const query = `{listCriteria{id,name,number}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        // console.log(resp.data);
        return message.warning('Error al obtener los criterios.');
      }

      this.setState({openModalCarrers: true, carrers: resp.data.data.listCriteria});
    });
  }

  handleCancelModalCarrers() {
    this.setState({openModalCarrers: false});
  }

  handleAssignCarrer(record) {
    const query = `mutation{assignCriteria(criteriaId: ${record.id}, criteriaCategoryId: ${this.state.category.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al asignar el registro.');
      }

      this.setState({openModalCarrers: false, carrers: []});
      this.getRecords(this.state.category.id);
    });
  }

  statusColor(status) {
    switch(status) {
      case 'Pendiente':
        return '#FBDB14';
      case 'En revisión':
        return '#FA8C16';
      case 'Con errores':
        return '#F5212D';
      case 'Autorizado':
        return '#52C51A';
      default:
        return '#FBDB14';
    }
  }

  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout>
        {/* Modal for new and edit */}
        <Modal
          open={this.state.openModal}
          title={this.state.modalTitle}
          onOk={this.handleSubmitModal.bind(this)}
          onCancel={this.handleCancelModal.bind(this)}
          footer={false} 
          width={900}
        >
          <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={this.state.selectedRecord} onFinish={this.handleSubmitModal.bind(this)} onFinishFailed={() => {}} autoComplete="off">
            <Form.Item label="Número de criterio" name="number" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item>

            <Form.Item label="Nombre" name="name" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item>

            <Form.Item label="Descripción" name="description" style={{marginBottom: 50}}>
              <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.recordDescription} onChange={(val) => {this.setState({recordDescription: val})}} style={{height: 200}} />
            </Form.Item>

            <br /> <br />

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
              <Button onClick={this.handleCancelModal.bind(this)} style={{marginRight: 5}}>Cancelar</Button>
              <Button type="primary" htmlType="submit">Guardar</Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Moal for assign carrer */}
        <Modal
          open={this.state.openModalCarrers}
          title={'Asignar categoría'}
          onOk={() => {}}
          onCancel={this.handleCancelModalCarrers.bind(this)}
          footer={false}
        >
          <List
          itemLayout="horizontal"
          dataSource={this.state.carrers}
          renderItem={item => (
            <List.Item key={`car-${item.id}`}>
              <List.Item.Meta                      
                title={<p style={{fontSize: '1.3em'}}>{item.name}</p>}
                style={{textAlign: 'left'}}
              />
              
              <Tooltip placement="top" title={'Asignar'}>
                <Button shape="circle" type="primary" ghost style={{marginRight: 5}} onClick={this.handleAssignCarrer.bind(this, item)}><ArrowRightOutlined /></Button>
              </Tooltip>
            </List.Item>
          )}/>
        </Modal>
        
        {/* Top menu */}
        <TopMenu />

        <Layout className="site-layout">
          <SideMenu />

          <Content style={{margin: '24px 16px 0', background: '#fff'}}>
            <div style={{padding: 24, textAlign: 'center'}}>
              <div style={{ margin: '16px 0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}>
                <div style={{marginBottom: 10}}>Ir a</div>
                <div style={{marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                  <a href="/organos-acreditadores" style={{background: '#1890ff', color: 'white', padding: '0px 5px', borderRadius: 5}}>Organos acreditadores</a>
                </div>
                <div style={{marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                  <a href={`/organo-acreditador/${this.state.acreditor.id}`} style={{background: '#1890ff', color: 'white', padding: '0px 5px', borderRadius: 5}}>Carreras de {this.state.acreditor.shortName}</a>
                </div>
                <div style={{marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                  <a href={`/acreditador/${this.state.acreditor.id}/carrera/${this.state.carrer.id}/categorias`} style={{background: '#1890ff', color: 'white', padding: '0px 5px', borderRadius: 5}}>Categorías de {this.state.carrer.name}</a>
                </div>
              </div>

              <br />

              <div className='container-between' style={{marginBottom: '30px', alignItems: 'center'}}>
                <h3 style={{marginBottom: 0, color: 'rgb(27, 60, 106)', textTransform: 'uppercase'}}>Criterios</h3>
                
                <div>
                  <Button icon={<PlusOutlined />} size="large" style={{backgroundColor: '#1B3C6A', color: '#fff'}} onClick={this.handleopenModalCarrers.bind(this)}>Asignar criterio
                  </Button>
                  <Button icon={<PlusOutlined />} size="large" style={{backgroundColor: '#1B3C6A', color: '#fff'}} onClick={this.handleCreateModal.bind(this)}>Nuevo criterio</Button>
                </div>
              </div>

              <br />

              <div className="site-layout-background" style={{ padding: 24, minHeight: 380, borderTop: '1px solid #ccc' }}>
                {/* Content */}
                <div className="">
                  <List
                    itemLayout="horizontal"
                    dataSource={this.state.records}
                    renderItem={item => (
                      <List.Item key={`car-${item.id}`}>
                        <List.Item.Meta  
                          title={
                            <div>
                              <span style={{marginRight: '6px', fontWeight: 'bold'}}>{item.criteria?.number}</span>
                              <span style={{fontSize: '1.3em', fontWeight: 'bold', color: 'rgb(27, 60, 106)'}}>{item.criteria?.name}</span>
                            </div>
                          }
                          description={
                            <div style={{width: '90%'}}>
                              {/* <p>Indicadores: {item.indicators ? item.indicators : 0}</p> */}
                              <div style={{padding: 5, borderRadius: 5}}>
                                {item.criteria?.description ? 
                                <div dangerouslySetInnerHTML={{ __html: item.criteria?.description }} />
                                :
                                <span>...</span>
                                }
                              </div>
                            </div>
                          } 
                          style={{textAlign: 'left'}}
                        />
                        
                        <Tooltip placement="top" title={'Detalles'}>
                          <Button shape="circle" type="primary" ghost style={{marginRight: 5}} href={`/acreditador/${this.state.acreditor.id}/carrera/${this.state.carrer.id}/categorias/${this.state.category.id}/criterio/${item.criteria?.id}/indicadores`}><FileSearchOutlined /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title={'Editar'}>
                          <Button shape="circle" type="primary" ghost style={{marginRight: 5}} onClick={this.handleEditModal.bind(this, item)}><EditOutlined /></Button>
                        </Tooltip>
                        <Tooltip placement="bottom" title={'Borrar'}>
                          <Button shape="circle" danger>
                            <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteRecord.bind(this, item)}>
                              <DeleteOutlined />
                            </Popconfirm>
                          </Button>
                        </Tooltip>
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default withParams(Categorias);
